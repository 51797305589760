import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import './HeroSection.css'; // Assuming you have a separate CSS file for hero section styles
// import '../css/modalform.css';
import { Button, Row, Col, Container } from 'react-bootstrap';
import HappyClientsSection from './HappyClientsSection';
import TestimonialSection from './testimonials';
import FeaturesSection from './FeaturesSection';
// import man from '../img/Group 95.png';
import man from '../img/1  (1).webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

import Marketing from './HomeSections/Marketing';
import girls from './../img/5 1.png';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from '@mui/material';

import { Fade, Box, Typography } from '@mui/material';
import ModalForm from "./HomeSections/ModalForm";
import VerticalStepper from "./HomeSections/MM";



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";












import {

  Stepper,
  Step,
  StepLabel,
  StepContent,

  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';


// import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


// Snackbar Transition Animation
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Step Data
const steps = [
  {
    label: 'Personal Details',
    fields: [
      { name: 'Name', type: 'text' },
      { name: 'Email', type: 'text' },
      { name: 'Phone Number', type: 'text' },
    ],
  },
  {
    label: 'Business Details',
    fields: [
      { name: 'Business Name', type: 'text' },
      {
        name: 'Which Stage is your Business Currently in?',
        type: 'buttonGroup',
        options: ['Ideation', 'Startup', 'Established'],
      },
      {
        name: 'What was your company turnover in the last year?',
        type: 'buttonGroup',
        options: ['0 - 49,99,999', '50,00,000 - 1,99,99,999', ' 2,00,00,000 - 4,99,99,999', '5,00,00,000 - 9,99,99,999', '10,00,00,000+'],
      },
      { name: 'In which year did you start your company?', type: 'text' },
    ],
  },
  {
    label: 'Services',
    fields: [
      {
        name: 'Select a Service',
        type: 'buttonGroup',
        options: [
          'Digital Marketing Services',
          'Offline Marketing',
          'Graphic Designing',
          'Financial Services',
          'Printing Services',
          'Accounting Services',
          'HR Services', 'Legal Services', 'Web Development'],
      },
    ],
  },
  {
    label: 'Page Management Service Pricing',
    fields: [
      {
        name: 'Page Management Service Pricing',
        type: 'buttonGroup',
        options: [
          { text: 'META (Fb & Instagram)', price: '₹2000' },
          { text: 'Google Ads', price: '₹3000' },
          { text: 'YouTube ', price: '₹2000' },
          { text: 'Twitter ', price: '₹1000' },
          { text: 'Google My Business ', price: '₹1000' },
        ],
      },
    ],
  },
  {
    label: 'Designing Service Pricing',
    fields: [
      {
        name: 'Designing Service Pricing',
        type: 'buttonGroup',
        options: [
          { text: 'Up to 5 creatives', price: '₹1000' },
          { text: 'Up to 10 creatives', price: '₹2000' },
          { text: 'Up to 15 creatives', price: '₹3000' },
          { text: 'Up to 20 creatives', price: '₹4000' },
          { text: 'Up to 25 creatives', price: '₹5000' },
          { text: 'Up to 30 creatives', price: '₹6000' },
          { text: 'Up to 40 creatives', price: '₹8000' },
          { text: 'Up to 50 creatives', price: '₹11000' },
          { text: 'Up to 60 creatives', price: '₹14000' },
        ],
      },
    ],
  },
  {
    label: 'Ad Management Service Pricing',
    fields: [
      {
        name: 'Ad Management Service Pricing',
        type: 'buttonGroup',
        options: [
          { name: 'META Ads Management', text: '    10% of your ad budget or 5,000 {Whichever is higher}' },
          { name: 'Google Ads Management', text: '10% of your ad budget or 5,000 {Whichever is higher}' },
          // { text: 'Up to 10 creatives', price: '₹2000' },

          // { text: 'Up to 60 creatives', price: '₹14000' },
        ],
      },
    ],
  },
  { label: 'Thank You' },
];




const useStyles = makeStyles(() => ({
  selectedButton: {
    backgroundColor: '#00758B!important',
    marginBottom: '10px!important',

    color: 'white !important',
    borderRadius: '0px!important',
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  normalButton: {
    fontSize: '16px!important',

    backgroundColor: 'white!important',
    color: 'black!important',
    marginBottom: '10px!important',
    borderRadius: '0px!important',
    // border: '1px solid white!important',
    borderColor: '#00758B',
    '&:hover': {
      backgroundColor: 'white!important',
    },
  },
}));



const HeroSection = () => {

  const leftColAnimation = useSpring({
    from: { transform: 'translateX(-100%)', opacity: 0 },
    to: { transform: 'translateX(0%)', opacity: 1 },
    config: { duration: 1200, tension: 280, friction: 30 },
  });

  const rightColAnimation = useSpring({
    from: { transform: 'translateX(100%)', opacity: 0 },
    to: { transform: 'translateX(0%)', opacity: 1 },
    config: { duration: 1200, tension: 280, friction: 30 },
  });


  // Define the loading state
  const [loading, setLoading] = useState(true);

  // Simulate loading for 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);






  // Setting up the useInView hook
  const [ref, inView] = useInView({ threshold: 0.1 });

  // Animation for left and right columns
  const leftColAnimationAbout = useSpring({
    from: { transform: 'translateX(-100%)', opacity: 0 },
    to: { transform: inView ? 'translateX(0%)' : 'translateX(-100%)', opacity: inView ? 1 : 0 },
    config: { duration: 800, tension: 200, friction: 30 },
  });

  const rightColAnimationAbout = useSpring({
    from: { transform: 'translateX(100%)', opacity: 0 },
    to: { transform: inView ? 'translateX(0%)' : 'translateX(100%)', opacity: inView ? 1 : 0 },
    config: { duration: 800, tension: 200, friction: 30 },
  });


  const isDesktop = useMediaQuery('(min-width:768px)');









  //modal

  // Modal state
  const [open, setOpen] = useState(false); // Manage modal state

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);













  // modal form logic 
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [selectedService, setSelectedService] = useState('');

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;



  // Update validateField to handle email and phone number validation
  const validateField = (name, value, pattern, minLength, maxLength) => {
    if (!value) return 'This field is required';

    // Phone number validation (must be exactly 10 digits)
    if (name === 'Phone Number') {
      if (!/^\d{10}$/.test(value)) return 'Phone number must be exactly 10 digits';
    }

    // Email validation (check if it matches a valid email pattern)
    if (name === 'Email') {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(value)) return 'Invalid email format';
    }

    // General validation for other fields
    if (pattern && !pattern.test(value)) return 'Invalid format';
    if (minLength && value.length < minLength) return `Must be at least ${minLength} digits`;
    if (maxLength && value.length > maxLength) return `Must be no more than ${maxLength} digits`;

    return '';
  };

  // Update handleInputChange to trigger validation for email and phone number
  const handleInputChange = (name, value, pattern, minLength, maxLength) => {
    const error = validateField(name, value, pattern, minLength, maxLength);
    setErrors((prev) => ({ ...prev, [name]: error }));
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleButtonGroupChange = (name, value, price = '') => {
    // Update formData with both value and price.
    const s = value.concat(" ", price);
    setFormData((prev) => ({
      ...prev,
      [name]: value, // Store service name
      [`${name}_price`]: price, // Store corresponding price
    }));

    setSelectedService(value);
    setSelectedPrice(price);

    // console.log(`Selected Service: ${value}, Price: ${price} ,  name: ${name}`); // Verify selected details.

    // my code 

    // if (name === 'Select a Service' && value !== 'Digital Marketing Services') {
    //   // Jump to the last step if any other service is selected
    //   setActiveStep(steps.length - 1);
    // }



    // Check for specific services to set the appropriate step
    if (name === 'Select a Service') {
      if (value === 'Digital Marketing Services') {
        setActiveStep(2); // Proceed to next step for Digital Marketing
      } else if (value === 'Legal Services' || value !== 'Digital Marketing Services') {
        setActiveStep(steps.length - 2); // Show Finish button for Legal Services or other services
      }
    }







    if (activeStep === 3) { // Step 4 for multiple selections
      const selectedOptions = formData[name] || [];
      const isSelected = selectedOptions.some(option => option.text === value);

      if (isSelected) {
        // If already selected, remove it from the array
        setFormData(prev => ({
          ...prev,
          [name]: selectedOptions.filter(option => option.text !== value),
        }));
      } else {
        // Otherwise, add it to the array
        setFormData(prev => ({
          ...prev,
          [name]: [...selectedOptions, { text: value, price }],
        }));
      }
    }

    else if (activeStep === 5) { // Step 6 for multiple selections
      const selectedOptions = formData[name] || [];
      const isSelected = selectedOptions.some(option => option.text === value);

      if (isSelected) {
        // If already selected, remove it from the array
        setFormData(prev => ({
          ...prev,
          [name]: selectedOptions.filter(option => option.text !== value),
        }));
      } else {
        // Otherwise, add it to the array
        setFormData(prev => ({
          ...prev,
          [name]: [...selectedOptions, { text: value, price }],
        }));
      }
    }
    else {
      // Single selection for other steps
      setFormData(prev => ({
        ...prev,
        [name]: value,
        [`${name}_price`]: price,
      }));
    }

  };


  const handleNext = () => {
    const currentFields = steps[activeStep].fields || [];
    const newErrors = {};
    let isValid = true;

    // Validate each field
    currentFields.forEach((field) => {
      const value = formData[field.name] || '';
      const pattern = field.name === 'Email' ? emailPattern : field.pattern;
      const error = validateField(field.name, value, pattern);
      if (error) isValid = false;
      newErrors[field.name] = error;
    });

    setErrors(newErrors);

    if (isValid) {
      setActiveStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
    }
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleReset = () => {
    setActiveStep(0);
    setFormData({});
    setErrors({});
  };

  const handleFinish = () => {
    // Move to the last step when Finish is clicked
    setActiveStep(steps.length - 1);
  };


  // const handleSubmit = () => setOpenDialog(true);





  // const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrl = 'https://squibfactory.com/apis';
  // console.log("API URL new ENV ddd: " + apiUrl);

  const handleSubmit = async () => {
    try {
      // console.log('Submitting form data:', formData); // Check formData before submission.

      // const response = await fetch('form_data.php', {
      const response = await fetch(`${apiUrl}/modal_form.php`, {

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      // console.log('Response status:', response.status);

      if (response.ok) {
        setSnackbarMessage('Form submitted successfully!');
        setShowSnackbar(true);
        handleReset();
      } else {
        const errorData = await response.json();
        setSnackbarMessage(`Error: ${errorData.message || 'Submission failed'}`);
        setShowSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setShowSnackbar(true);
    }
  };






  // const [selectedService, setSelectedService] = useState('');
  const [selectedPrice, setSelectedPrice] = useState(''); // Store selected price


  const isNotDigitalMarketing = formData['Select a Service'] !== 'Digital Marketing Services';












  const handleCloseDialog = () => {
    setOpenDialog(false);
    setShowSnackbar(true);
    handleReset();
  };

  const handleCloseSnackbar = () => setShowSnackbar(false);


  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);






  //  scroll on the next nextions 
  // Function to scroll to the second section using the id
  const scrollToSecondSection = () => {
    const section = document.getElementById("second-section");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };


  return (
    <>


      <section className="hero-section">
        <div className="container position-relative">
          <div className="row align-items-center hero-section-row">
            <animated.div style={leftColAnimation} className="col-lg-6 col-md-12 home-text-col">
              <div className="hero-content text-white">

                <h2>Let's Start Something Big Together</h2>
                <h1>ONE-STOP</h1>
                <p>One-Stop for all Your Business Needs</p>
                <button className="btn learn-more-btn" onClick={scrollToSecondSection}>Learn More</button>


              </div>
            </animated.div>

            <animated.div style={rightColAnimation} className="col-lg-6 mt-4 col-md-12 text-center justify-content-center">

              <>
                <img className="man-image" src={man} alt="man" />

                <button className="contact-btn let-contact-btn" onClick={() => setLgShow(true)} >
                  Let’s Connect
                </button>
              </>

            </animated.div>
          </div>
        </div>
      </section>






      {/* modal form  */}

      {/* <Button onClick={() => setLgShow(true)} style={{ margin: '15rem' }}> modal</Button> */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <div
            className='mainContainer home-modal-form-padding'
            style={{ display: 'flex', maxWidth: 'auto', padding: '20px' }}
          >
            <div className='verticalStepperContainer' style={{ width: '40px', marginRight: '20px', flexShrink: 0 }}>
              <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                {steps.map((step, index) => (
                  <Step key={step.label} completed={index < activeStep}>
                    <StepLabel>{index.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className='contentContainer' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography className="form-heading-home" variant="h4" component="h2" align="left" color="black" sx={{ mb: 3 }}
                style={{ fontSize: { xs: '10px', sm: '20px' }, }}
              >
                {steps[activeStep].label}
              </Typography>
              <div className='container button-content-form-home home-modal-form-input'>
                <div className='content-form-home modal-home-input-section'>
                  {steps[activeStep]?.fields?.map((field) => (
                    <Box key={field.name} sx={{ mb: 2 }}>
                      {field.type === 'text' ? (
                        <TextField
                          label={field.name}
                          fullWidth
                          variant="outlined"
                          error={!!errors[field.name]}
                          helperText={errors[field.name]}
                          onChange={(e) => handleInputChange(field.name, e.target.value, field.pattern)}
                        />
                      ) : field.type === 'buttonGroup' ? (
                        <Box>
                          <Typography variant="subtitle1" sx={{ mb: 1 }}
                            style={{

                              fontSize: { xs: '10px', sm: '38px' }, // 10px for extra small screens, 18px for small and up

                              color: '#00758B',
                              fontFamily: 'Poppins',
                              fontWeight: '500',
                              lineHeight: '21px',


                              //                           font-family: Poppins;
                              // font-size: 14px;
                              // font-weight: 400;
                              // line-height: 21px;
                              // text-align: left;
                              // text-underline-position: from-font;
                              // text-decoration-skip-ink: none;

                            }}
                          >
                            {field.name}
                          </Typography>

                          {/* Step 2 and Step 3 use different layouts */}
                          {activeStep === 1 ? ( // Step 2 uses row layout
                            <Row>
                              {field.options.map((option) => (
                                <Col key={option} sm={4} md={3} xs={6}>
                                  <Button
                                    onClick={() => handleButtonGroupChange(field.name, option)}
                                    className={
                                      formData[field.name] === option
                                        ? classes.selectedButton
                                        : classes.normalButton
                                    }
                                    sx={{ mr: 1, mb: 1 }}
                                    style={{
                                      fontSize: { sm: '10px', md: '18px' }, // 10px for extra small screens, 18px for small and up

                                    }}
                                  >
                                    {option}
                                  </Button>
                                </Col>
                              ))}
                            </Row>
                          ) : activeStep === 2 ? ( // Step 3 uses row layout with additional price data
                            <Row>
                              {field.options.map((option) => (
                                <Col key={option} sm={6} md={8}>
                                  <Button
                                    onClick={() => {
                                      handleButtonGroupChange(field.name, option);
                                      // console.log(`Selected Service: ${option}`);  // Debugging: Log the selected service

                                      // Check if the selected service is 'Digital Marketing Services'
                                      if (option === 'Digital Marketing Services') {
                                        // console.log('Digital Marketing Service selected');
                                        // Proceed to the next step for Digital Marketing Services
                                        // setActiveStep(activeStep + 1); // Move to the next step
                                      } else {
                                        // console.log('Other Service selected');
                                        // Stay on the same step for other services and update button to 'Finish'
                                        setActiveStep(activeStep); // Stay on the same step
                                      }
                                    }}
                                    className={
                                      formData[field.name] === option
                                        ? classes.selectedButton
                                        : classes.normalButton
                                    }
                                    sx={{ mr: 1, mb: 1 }}
                                    style={{
                                      fontSize: { sm: '10px', md: '28px' }, // Adjust button size
                                    }}
                                  >
                                    {option}
                                  </Button>
                                </Col>
                              ))}
                            </Row>



                          ) : activeStep === 3 ? ( // Step 3 uses row layout with additional price data
                            <Box display="flex" flexDirection="column">
                              <Row>
                                {field.options.map((option) => (
                                  <Col key={option.text} sm={10} md={12}>
                                    <Button
                                      onClick={() =>
                                        handleButtonGroupChange(field.name, option.text, option.price)
                                      }
                                      className={
                                        formData[field.name]?.some((selected) => selected.text === option.text)
                                          ? classes.selectedButton
                                          : classes.normalButton
                                      }
                                      sx={{ mr: 1, mb: 1 }}
                                    >
                                      {option.text} {option.price}
                                    </Button>
                                  </Col>
                                ))}
                              </Row>
                            </Box>
                          ) : activeStep === 5 ? ( // Step 6 uses row layout with additional price data
                            <Box display="flex" flexDirection="column">
                              <Row>
                                {field.options.map((option) => (
                                  <Col key={option.text} sm={10} md={12}>
                                    <h2 className="adds-heading-home">{typeof option === 'string' ? option : `${option.name}`}</h2>

                                    <Button
                                      onClick={() =>
                                        handleButtonGroupChange(field.name, option.text, option.price)
                                      }
                                      className={
                                        formData[field.name]?.some((selected) => selected.text === option.text)
                                          ? classes.selectedButton
                                          : classes.normalButton
                                      }
                                      sx={{ mr: 1, mb: 1 }}
                                    >
                                      {option.text} {option.price}
                                    </Button>
                                  </Col>
                                ))}
                              </Row>
                            </Box>

                          ) : ( // For other steps (single selection)
                            <Box display="flex" flexDirection="column">
                              {field.options.map((option) => (
                                <Row key={typeof option === 'string' ? option : option.text}>
                                  <Col sm={12} md={10}>
                                    <Button
                                      onClick={() =>
                                        handleButtonGroupChange(
                                          field.name,
                                          typeof option === 'string' ? option : option.text,
                                          option.price || null
                                        )
                                      }
                                      className={
                                        formData[field.name] === (typeof option === 'string' ? option : option.text)
                                          ? classes.selectedButton
                                          : classes.normalButton
                                      }
                                      sx={{ mb: 1 }}

                                      style={{

                                        fontSize: { xs: '16px', sm: '18px' }, // 10px for extra small screens, 18px for small and up

                                      }}
                                    >
                                      {typeof option === 'string' ? option : `${option.text} ${option.price}`}
                                    </Button>
                                  </Col>
                                </Row>
                              ))}
                            </Box>


                          )}
                        </Box>

                      ) : null}
                    </Box>
                  ))}
                </div>

                {/* // Update button rendering logic */}

                {/* Update button rendering logic */}
                {/* Update button rendering logic */}
                {/* Update button rendering logic */}
                <div className="button-content-form">
                  {activeStep < steps.length - 1 && (
                    <Box
                      className="backNextbtn_homee"
                      sx={{ mt: 2, display: 'flex', justifyContent: 'end', gap: '8px' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        Back
                      </Button>
                      <Button
                        className="next-button-home"
                        style={{
                          fontSize: { xs: '10px', sm: '18px' },
                          padding: '6px 32px',
                          marginRight: '10px',
                        }}
                        variant="contained"
                        onClick={() => {
                          if (activeStep === steps.length - 1 || activeStep === 5) {
                            // If "Finish" is clicked on the last step, submit the form
                            handleSubmit();
                          } else if (activeStep === 2) {
                            // Handle Step 2 logic
                            if (formData['Select a Service'] !== 'Digital Marketing Services') {
                              handleSubmit(); // Submit the form and finish
                            } else {
                              handleNext(); // Proceed to the next step for Digital Marketing Services
                            }
                          } else {
                            // Proceed to the next step for other steps
                            handleNext();
                          }
                        }}
                      >
                        {
                          (activeStep === 5 || activeStep === steps.length - 2 || activeStep === steps.length - 1)
                            ? 'Finish' // Display 'Finish' for Step 5, second-to-last, and last step
                            : (activeStep === 2)
                              ? (isNotDigitalMarketing ? 'Finish' : 'Next') // Show 'Finish' if not "Digital Marketing Services", otherwise 'Next' for Step 2
                              : (activeStep === 0 || activeStep === 1 || activeStep === 3 || activeStep === 4)
                                ? 'Next' // Show 'Next' for Step 0, 1, 3, and 4
                                : 'Finish' // Default to 'Finish' for all other steps
                        }
                      </Button>
                    </Box>
                  )}
                </div>





              </div>



            </div>
          </div>
        </Modal.Body>
      </Modal>






      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Form Data</DialogTitle>
        <DialogContent>
          {Object.entries(formData).map(([key, value]) => (
            <Box key={key} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {key}:
              </Typography>
              <Typography>{value}</Typography>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Thank you for your time!
        </Alert>
      </Snackbar>




      <ModalForm open={open} handleClose={handleClose} />
      {/* <VerticalStepper open={open} handleClose={handleClose} /> */}







      <Marketing />



      <section className="about-us-section1" ref={ref} >
        <div className="about-us-section">
          <Container className="position-relative p-0">
            <Row className="row-girls-sections">
              {/* Apply animation only on desktop */}
              {isDesktop ? (
                <>
                  <animated.div style={leftColAnimationAbout} className='col-md-7 p-0'>
                    <div className="girl-image-wrapper">
                      <img src={girls} alt="Girl" className="girl-image" />
                    </div>
                  </animated.div>
                  <animated.div style={rightColAnimationAbout} className="col-md-5 d-flex  flex-column right-side-about-heading justify-content-center align-items-start text-section">
                    <div className="content-wrapper">
                      <h3 >About Us</h3>
                      <h4>A Visionary aim to deliver ease-of-doing-business for all !!</h4>
                      <p>The company was established with a view to support businesses to expand and help them grow exponentially.</p>
                      <p className="mt-5 pt-4">We provide services related to different industries and have a team of experienced members from various backgrounds.</p>
                      <Button className="outline-primary-white2 get-start-home-btn" href="/about">Get Started Now</Button>
                    </div>
                  </animated.div>
                </>
              ) : (
                <>
                  {/* No animation on mobile */}
                  <div className='col-md-7 p-0'>
                    <div className="girl-image-wrapper">
                      <img src={girls} alt="Girl" className="girl-image" />
                    </div>
                  </div>
                  <div className="col-md-5 d-flex  flex-column right-side-about-heading justify-content-center align-items-start text-section">
                    <div className="content-wrapper">
                      <h3>About Us</h3>
                      <h4>A Visionary aim to deliver ease-of-doing-business for all !!</h4>
                      <p>The company was established with a view to support businesses to expand and help them grow exponentially.</p>
                      <p className="mt-5 pt-4">We provide services related to different industries and have a team of experienced members from various backgrounds.</p>
                      <Button className="outline-primary-white2 get-start-home-btn" onClick={() => setLgShow(true)}>Get Started Now</Button>
                    </div>
                  </div>
                </>
              )}
            </Row>
          </Container>
        </div>
      </section>





      <FeaturesSection />



      <HappyClientsSection />
      <TestimonialSection />

      <div style={{ marginBottom: '7.5rem', marginTop: '7.5rem' }}>
        <section className="bg-color-green mt-5 mb-5"
          style={{
            background: 'linear-gradient(90deg, #000000 0%, #00758B 100%)',
            width: '100%',
            height: '39px',
            position: 'relative'
          }}>
          <div className="container" style={{ position: 'relative', zIndex: 1 }}>
            <div className="row justify-content-center">
              <div className="col-md-7 text-center d-flex align-items-center justify-content-between schdule-call-caontainer"
                style={{
                  border: '1px solid black',
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  padding: '20px',
                  zIndex: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '12px',
                  justifyContent: 'space-between'
                }}>
                <h2 style={{ fontSize: '25px', fontWeight: '500', color: 'black', marginRight: '20px' }}>
                  Do you have any questions? <br /> Feel free to connect with us
                </h2>
                <a className="btn" style={{
                  fontSize: '18px',
                  fontWeight: '400',
                  color: '#333',
                  lineHeight: '1.6',
                  height: '41px',
                  width: '194px',
                  border: '1px solid rgba(0, 0, 0, 1)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'black',
                  borderRadius: '0px'
                }} onClick={() => setLgShow(true)}>
                  Schedule A Call
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HeroSection;
